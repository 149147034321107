import {
  createTRPCProxyClient,
  httpBatchLink,
  HTTPHeaders,
  TRPCClientError,
} from '@trpc/client';
import type { AppRouter } from '@vrstar/core/src/router';
import { firstValueFrom } from 'rxjs';
import { token$ } from '@/utils/auth';
import { selectedBic$ } from '@/utils/selected-bank';

export function isTRPCClientError(
  cause: unknown,
): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

export const coreApi = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${import.meta.env.VITE_CORE_URL}/trpc`,
      async headers() {
        const headers: HTTPHeaders = {};
        const token = await firstValueFrom(token$);

        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        if (selectedBic$.value) {
          headers['Selected-Bic'] = selectedBic$.value;
        }

        return headers;
      },
    }),
  ],
});
