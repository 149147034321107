<script setup lang="ts">
import RequiredPasswordChangeModal from '@/components/modals/RequiredPasswordChangeModal.vue';
import ResetPasswordModal from '@/components/modals/ResetPasswordModal.vue';
import VideoCallModal from '@/components/video-call/VideoCallModal.vue';
import { useUserStore } from '@/store/userStore';
import { storeToRefs } from 'pinia';

const { isVideocallAgent } = storeToRefs(useUserStore());
</script>

<template>
  <ResetPasswordModal />
  <RequiredPasswordChangeModal />
  <VideoCallModal
    v-if="isVideocallAgent" />
</template>
