import { computed, ref } from 'vue';

const persistedDevicesStorageKey = 'vr-video-devices';
interface PersistedDevices {
  selectedCamera?: MediaDeviceInfo;
  selectedAudioIn?: MediaDeviceInfo;
  selectedAudioOut?: MediaDeviceInfo;
}

export type MediaDeviceType = 'videoinput' | 'audioinput' | 'audiooutput';

const availableDevices = ref<MediaDeviceInfo[]>([]);

const supportsAudioOutputSetting = ref(false);
if ('setSinkId' in HTMLMediaElement.prototype) {
  supportsAudioOutputSetting.value = true;
}

const availableCameras = computed(() => (
  availableDevices.value
    ?.filter((d) => d.kind === 'videoinput' && d.label)
));
const availableAudioIn = computed(() => (
  availableDevices.value
    ?.filter((d) => d.kind === 'audioinput' && d.label)
));
const availableAudioOut = computed(() => (
  availableDevices.value
    ?.filter((d) => d.kind === 'audiooutput' && d.label)
));

const selectedCamera = ref<MediaDeviceInfo | undefined>();
const selectedAudioIn = ref<MediaDeviceInfo | undefined>();
const selectedAudioOut = ref<MediaDeviceInfo | undefined>();

const fetchAvailableDevices = async () => {
  availableDevices.value = (await navigator.mediaDevices?.enumerateDevices())
    ?.map((d) => d.toJSON());
};

if (navigator.mediaDevices) {
  navigator.mediaDevices.ondevicechange = () => {
    fetchAvailableDevices();
  };
}

const persistDevices = async () => {
  localStorage.setItem(persistedDevicesStorageKey, JSON.stringify({
    selectedCamera: selectedCamera.value,
    selectedAudioIn: selectedAudioIn.value,
    selectedAudioOut: selectedAudioOut.value,
  } as PersistedDevices));
};

const selectDevice = async (device: MediaDeviceInfo, type: MediaDeviceType) => {
  if (type === 'videoinput') {
    selectedCamera.value = device;
  } else if (type === 'audioinput') {
    selectedAudioIn.value = device;
  } else if (type === 'audiooutput') {
    selectedAudioOut.value = device;
  }

  await persistDevices();
};

const hasCamera = computed(() => availableCameras.value?.length > 0);
const hasAudioIn = computed(() => availableAudioIn.value?.length > 0);
const hasRequiredMediaDevices = computed(() => (
  hasCamera.value && hasAudioIn.value
));

const checkMediaDevices = async () => {
  await fetchAvailableDevices();

  return hasRequiredMediaDevices.value;
};

const getDefaultDevice = async (type: MediaDeviceType, persistedDevice?: MediaDeviceInfo) => {
  const devices = availableDevices.value?.filter((d) => d.kind === type);

  // no devices of given kind found at all, break
  if (!devices || devices.length === 0) {
    return undefined;
  }

  // try to load persisted device if still available
  if (
    persistedDevice
      && devices.find((d) => d.deviceId === persistedDevice.deviceId)
  ) {
    return persistedDevice;
  }

  // check if there is a device with id "default", use that
  const defaultDevice = devices.find((d) => d.deviceId === 'default');
  if (defaultDevice) {
    return defaultDevice;
  }

  // fallback to the first available device
  return devices[0];
};

const loadDefaultDevices = async () => {
  const devicesStorageEntry = localStorage.getItem(persistedDevicesStorageKey);
  const persistedDevices = devicesStorageEntry
    ? JSON.parse(devicesStorageEntry) as PersistedDevices
    : undefined;

  selectedCamera.value = await getDefaultDevice('videoinput', persistedDevices?.selectedCamera);
  selectedAudioIn.value = await getDefaultDevice('audioinput', persistedDevices?.selectedAudioIn);
  selectedAudioOut.value = await getDefaultDevice('audiooutput', persistedDevices?.selectedAudioOut);
};

const initialise = async () => {
  await fetchAvailableDevices();
  await loadDefaultDevices();
};

initialise();

export function useMediaDevices() {
  return {
    availableDevices,
    availableCameras,
    availableAudioIn,
    availableAudioOut,
    checkMediaDevices,
    fetchAvailableDevices,
    loadDefaultDevices,
    selectedCamera,
    selectedAudioIn,
    selectedAudioOut,
    hasRequiredMediaDevices,
    selectDevice,
    supportsAudioOutputSetting,
  };
}
