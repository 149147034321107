import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  take,
  timer,
} from 'rxjs';
import { ref } from 'vue';
import { user$ } from './auth';

const authInitialised$ = new BehaviorSubject<boolean>(false);

const appReady$ = combineLatest([authInitialised$, user$])
  .pipe(
    filter(([authReady]) => authReady),
    map(() => true),
    distinctUntilChanged(),
  );

const appReady = ref(false);

combineLatest([
  appReady$,
  timer(400).pipe(take(1), map(() => true)),
]).subscribe(([appReadyState]) => {
  appReady.value = appReadyState;
});

export {
  authInitialised$, appReady$, appReady,
};
