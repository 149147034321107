import { useI18n } from 'vue-i18n';
import { supportedLangs, defaultLocale } from '@/utils/i18n';

export function useLocale() {
  const { locale } = useI18n();

  const getLocaleFromLocalStorage = () => {
    const localStorageLocale = localStorage.getItem('locale') || '';
    return localStorageLocale && (
      supportedLangs as readonly string[]
    ).includes(localStorageLocale) ? localStorageLocale : null;
  };

  const updateLocale = (newLocale?: string) => {
    const localeSetting = newLocale || getLocaleFromLocalStorage() || defaultLocale;

    localStorage.setItem('locale', localeSetting);

    locale.value = localeSetting;
  };

  return { updateLocale, locale };
}
