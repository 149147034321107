import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { RouteMeta } from 'vue-router';
import { roles, RolesType, UserClaims } from '@/types/User';
import { User, UserProfile } from '@/utils/auth';

export const useUserStore = defineStore('userStore', () => {
  const user = ref<User | null>();
  const userClaims = computed<UserClaims | null>(() => {
    if (user.value) {
      return {
        bic: user.value.idTokenResult.claims?.bic ?? undefined,
        userToken: user.value.idTokenResult.token,
        userRoles: user.value.idTokenResult.claims?.roles ?? undefined,
        partnerIds: user.value.idTokenResult.claims?.partnerIds ?? undefined,
      };
    }
    return null;
  });

  const userRoles = computed(() => userClaims.value?.userRoles);
  const userToken = computed(() => userClaims.value?.userToken);
  const partnerIds = computed(() => userClaims.value?.partnerIds);

  const isBankUser = computed(() => !!userClaims.value?.bic);
  const isContentAdmin = computed(() => userRoles.value?.includes(roles.ContentAdmin));
  const isSystemAdmin = computed(() => userRoles.value?.includes(roles.SystemAdmin));
  const isBankAdmin = computed(() => userRoles.value?.includes(roles.BankAdmin));
  const isOrganizerManager = computed(() => userRoles.value?.includes(roles.OrganizerManager));
  const isOrganizerAgent = computed(() => userRoles.value?.includes(roles.OrganizerAgent));
  const isPartnerAgent = computed(() => userRoles.value?.includes(roles.PartnerAgent));
  const isPartnerManager = computed(() => userRoles.value?.includes(roles.PartnerManager));
  const isVideocallAgent = computed(() => userRoles.value?.includes(roles.VideocallAgent));

  const canCreateOrganizer = computed(() => !!(isOrganizerManager.value || isSystemAdmin.value || isContentAdmin.value));
  const canDeleteOrganizer = computed(() => !!(isOrganizerManager.value || isSystemAdmin.value || isContentAdmin.value));

  const canCreateVenue = computed(() => !!(isOrganizerAgent.value || isOrganizerManager.value || isSystemAdmin.value || isContentAdmin.value));

  const canCreateOffer = computed(() => !!(isPartnerManager.value || isPartnerAgent.value));
  const canPublishOffer = computed(() => !!isPartnerManager.value);

  const canPublishEvent = computed(() => !!(isOrganizerManager.value || isSystemAdmin.value || isContentAdmin.value));

  const userProfile = computed<UserProfile | undefined>(() => user.value?.profile);
  const hasPasswordChange = computed(() => userProfile.value?.requiredUserActions?.includes('change_password'));

  const checkRoles = async (rolesToCheck?: RolesType | RolesType[]) => {
    if (!rolesToCheck) {
      return true;
    }

    if (!user.value) {
      return false;
    }

    if (!userRoles.value || !Array.isArray(userRoles.value)) {
      return false;
    }

    const grantedRoles = typeof rolesToCheck === 'string' ? [rolesToCheck] : rolesToCheck;

    return !!(grantedRoles.find((r) => userRoles.value?.includes(r)));
  };

  const accessGranted = async (meta?: RouteMeta): Promise<boolean> => {
    // always true if there are no route meta information
    if (!meta) {
      return true;
    }

    const rolesToCheck = meta.roles;

    const rolesCheckResult = await checkRoles(rolesToCheck);

    if (!rolesCheckResult) {
      return false;
    }

    if (meta.permissionGuards?.afterRoleCheck) {
      return meta.permissionGuards.afterRoleCheck({
        meta,
        user: user.value ?? undefined,
      });
    }

    return rolesCheckResult;
  };

  const updateUser = (newUser: User | null) => {
    user.value = newUser;
  };

  return {
    isBankUser,
    userClaims,
    userToken,
    userRoles,
    user,
    userProfile,
    isBankAdmin,
    isOrganizerManager,
    isOrganizerAgent,
    isPartnerAgent,
    isPartnerManager,
    isContentAdmin,
    isSystemAdmin,
    isVideocallAgent,
    hasPasswordChange,
    partnerIds,
    accessGranted,
    canCreateOrganizer,
    canDeleteOrganizer,
    canCreateVenue,
    canCreateOffer,
    canPublishOffer,
    canPublishEvent,
    updateUser,
  };
});
