import { computed, ref, watch } from 'vue';
import { filter, fromEventPattern, switchMap } from 'rxjs';
import { z } from 'zod';
import { coreApi } from '@/api/core';
import { db } from '@/firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { GetVideocallJoinTokenOutputValidator } from '@vrstar/core/dist/src/services/videocalls/schemas';
import { User, user$ } from '@/utils/auth';

const user = ref<User | null>();
user$.subscribe((u) => {
  user.value = u;
});

interface CallDocument {
  status: 'AVAILABLE' | 'BUSY' | 'OFFLINE';
  uid: string;
  call?: {
    attemptStart?: string;
    callStart?: string;
    callId?: string;
    ready?: boolean;
  }
}

const callDocument = ref<CallDocument | null>(null);

user$.pipe(
  filter(Boolean),
  switchMap((u) => {
    const docRef = doc(collection(db, `videocalls/${u.idTokenResult.claims?.bic}/agents`), u.auth.uid);

    // @see https://stackoverflow.com/a/56503403
    return fromEventPattern(
      (handler) => onSnapshot(docRef, handler),
      (handler, unsubscribe) => unsubscribe(),
      (d) => d.data(),
    );
  }),
).subscribe((call) => {
  callDocument.value = call;
});

const online = computed(() => callDocument.value?.status && ['AVAILABLE', 'BUSY'].includes(callDocument.value.status));
const currentStatus = computed(() => callDocument.value?.status ?? 'OFFLINE');

const isRinging = computed(() => currentStatus.value === 'BUSY' && !callDocument.value?.call?.callStart);
const inCall = computed(() => currentStatus.value === 'BUSY' && !!callDocument.value?.call?.callStart);

const joiningCall = ref(false);
const leavingCall = ref(false);

watch(inCall, () => {
  if (inCall.value && joiningCall.value) {
    joiningCall.value = false;
  }

  if (!inCall.value && leavingCall.value) {
    leavingCall.value = false;
  }
});

const uiState = computed(() => {
  if (joiningCall.value) {
    return 'joining';
  }

  if (leavingCall.value) {
    return 'leaving';
  }

  if (inCall.value) {
    return 'in-call';
  }

  if (isRinging.value) {
    return 'ringing';
  }

  return 'idle';
});

const showVideoCallModal = computed(() => isRinging.value || inCall.value);

const changeState = async (isOnline: boolean) => {
  await coreApi.videocalls.agent.setStatus.mutate({
    status: isOnline ? 'AVAILABLE' : 'OFFLINE',
  });
};

const acceptCall = async () => {
  joiningCall.value = true;
  return await coreApi
    .videocalls
    .agent
    .callResponse
    .mutate({ answer: true }) as z.infer<typeof GetVideocallJoinTokenOutputValidator>;
};

const rejectCall = async () => await coreApi
  .videocalls
  .agent
  .callResponse
  .mutate({ answer: false }) as boolean;

export function useVideoCall() {
  return {
    online,
    changeState,
    currentStatus,
    isRinging,
    inCall,
    showVideoCallModal,
    acceptCall,
    rejectCall,
    uiState,
    leavingCall,
    joiningCall,
  };
}
