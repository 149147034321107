import { useToast } from 'primevue/usetoast';
import type { ErrorMessageCodesType } from '@vrstar/lib/browser';
import { errorMessageCodes } from '@vrstar/lib/browser';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { isTRPCClientError } from '@/api/core';
import { useCurrentUser } from 'vuefire';
import { useModalStore } from '@/store/modalStore';
import { storeToRefs } from 'pinia';
import { logout } from '@/utils/logout';

type UseTRPCErrorOptions = {
  showToast?: boolean;
};

export const useTRPCError = () => {
  const toast = useToast();
  const errorMessage = ref<string>();
  const { t, te } = useI18n();

  // For TOASTS to show the component <Toast /> from @primevue/toast
  // needs to be present in the component or parent of component
  // that consumes this composable
  const handleShowToast = () => {
    if (!errorMessage.value) {
      return;
    }
    toast.add({
      severity: 'error',
      summary: t('notifications.fail'),
      detail: errorMessage.value,
      life: 3000,
    });
  };

  const parseError = (error: boolean | string | undefined) => Boolean(errorMessage.value || error);

  const parseTRPCError = async (tprcErrorMessages: ErrorMessageCodesType) => {
    const errorMessageCode = errorMessageCodes[tprcErrorMessages];
    const messageExists = te(`trpc_error.${errorMessageCode}`);

    if (errorMessageCode === 'UNAUTHORIZED') {
      const user = useCurrentUser();
      if (user.value) {
        const { hasBeenUnauthorized } = storeToRefs(useModalStore());
        hasBeenUnauthorized.value = true;
        await logout();
      }
    } else if (!errorMessageCode || !messageExists) {
      errorMessage.value = t('trpc_error.UNKNOWN_ERROR');
      return;
    }
    errorMessage.value = t(`trpc_error.${errorMessageCode}`);
  };

  const handleTRPCError = (e: unknown, options: UseTRPCErrorOptions = { showToast: false }) => {
    if (isTRPCClientError(e)) {
      parseTRPCError(e.message as ErrorMessageCodesType);
    } else {
      errorMessage.value = t('trpc_error.UNKNOWN_ERROR');
    }
    if (options.showToast) handleShowToast();
  };

  return {
    errorMessage,
    handleTRPCError,
    parseError,
  };
};
