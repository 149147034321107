import { onIdTokenChanged } from 'firebase/auth';
import { auth } from '@/firebase';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router/types';
import { authInitialised$ } from '@/utils/app-state';
import { authUser$, user$ } from '@/utils/auth';
import { useUserStore } from '@/store/userStore';

export function initFirebaseAuth() {
  const router = useRouter();

  const { updateUser } = useUserStore();
  user$.subscribe((user) => {
    updateUser(user);

    if (user && !authInitialised$.value) {
      authInitialised$.next(true);
    }
  });

  const initAuth = () => {
    onIdTokenChanged(auth, async (changedUser) => {
      authUser$.next(changedUser);

      if (!changedUser) {
        if (router.currentRoute.value.meta?.requireAuth) {
          router.replace({ name: routeNames.Login });
        }
        authInitialised$.next(true);
      }
    });
  };
  return { initAuth };
}
