import { createI18n } from 'vue-i18n';
import { z } from 'zod';

import de from '@/lang/de.json';
import en from '@/lang/en.json';

const isDev = import.meta.env.DEV;

type MessageSchema = typeof en;

export type SupportedLangsType = 'de' | 'en';

export const supportedLangs: SupportedLangsType[] = ['de', 'en'];
export const defaultLocale: SupportedLangsType = isDev ? 'en' : 'de';

export const i18n = createI18n<[MessageSchema], SupportedLangsType>({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: isDev ? 'en' : 'de',
  globalInjection: true,
  messages: { de, en },
});

export const { global: { t, te } } = i18n;

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.minimum === 1) {
      return { message: t('validation_errors.nonempty') };
    }

    return { message: t('validation_errors.min', { requiredLength: issue.minimum }) };
  }

  if (issue.code === z.ZodIssueCode.invalid_string) {
    if (issue.validation === 'email') {
      return { message: t('validation_errors.email') };
    }

    if (issue.validation === 'url') {
      return { message: t('validation_errors.url') };
    }
  }

  if (issue.code === z.ZodIssueCode.too_big && issue.maximum) {
    return { message: t('validation_errors.too_big_string', { requiredLength: issue.maximum }) };
  }

  if (issue.code === z.ZodIssueCode.invalid_type && (issue.received === 'undefined' || issue.received === 'null')) {
    return { message: t('validation_errors.nonempty') };
  }

  if (issue.code === z.ZodIssueCode.invalid_date) {
    return { message: t('validation_errors.nonempty') };
  }

  if (issue.code === z.ZodIssueCode.invalid_literal && issue.expected === true) {
    return { message: t('validation_errors.nonempty') };
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
