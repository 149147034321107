import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { z } from 'zod';
import { IdTokenResult, User as AuthUser } from 'firebase/auth';
import { coreApi, isTRPCClientError } from '@/api/core';
import { GetUserOutputValidator } from '@vrstar/core/dist/src/services/common/userSchema';
import { auth } from '@/firebase';
import { GetBankDetailRecordValidator } from '@vrstar/core/dist/src/services/endUsers/schemas';
import { selectedBic$, restore as restoreSelectedBic } from '@/utils/selected-bank';

export type UserProfile = z.infer<typeof GetUserOutputValidator>;
export type BankDetails = z.infer<typeof GetBankDetailRecordValidator>;

export interface User {
  auth: AuthUser;
  idTokenResult: IdTokenResult;
  profile: UserProfile;
  bank?: BankDetails;
}

export const authUser$ = new BehaviorSubject<AuthUser | null>(null);

const idTokenResult$ = authUser$.pipe(
  switchMap((user) => (user ? user.getIdTokenResult() : of(null))),
);

const userProfile$: Observable<UserProfile | null> = authUser$.pipe(
  switchMap((user) => (user ? coreApi.user.getProfile.query() : of(null))),
  catchError((error) => {
    if (isTRPCClientError(error) && error.message === 'UNAUTHORIZED') {
      auth.signOut().then(() => {
        location.reload();
      });
    }

    return of(null);
  }),
  map((result) => result as UserProfile | null),
);

export const forceBankDetailsUpdate$ = new BehaviorSubject<void>(undefined);

const bankDetails$ = combineLatest([
  idTokenResult$,
  selectedBic$,
  forceBankDetailsUpdate$,
]).pipe(
  map(([idTokenResult, selectedBic]) => {
    if (idTokenResult) {
      if (selectedBic) {
        return selectedBic;
      }

      if (idTokenResult.claims?.bic) {
        return idTokenResult.claims.bic;
      }
    }

    return null;
  }),
  switchMap((bic: string | null) => {
    if (bic) {
      return coreApi.enduser.bank.query({ bic });
    }

    return of(null);
  }),
  map((result) => result as BankDetails | null),
);

export const user$ = combineLatest([
  authUser$,
  idTokenResult$,
  userProfile$,
  bankDetails$,
]).pipe(
  map(([authUser, idTokenResult, profile, bankDetails]) => {
    if (!authUser || !idTokenResult || !profile) {
      return null;
    }

    return {
      auth: authUser,
      idTokenResult,
      profile,
      bank: bankDetails,
    } as User;
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const token$ = idTokenResult$.pipe(
  map((idTokenResult) => idTokenResult?.token ?? undefined),
  shareReplay({ bufferSize: 1, refCount: false }),
);

authUser$.subscribe((authUser) => {
  if (authUser) {
    restoreSelectedBic();
  } else {
    selectedBic$.next(null);
  }
});
