<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import {
  connect, CreateLocalAudioTrackOptions, CreateLocalTrackOptions, createLocalTracks, LocalTrack, RemoteTrack, Room,
} from 'twilio-video';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useVideoCall } from '@/composables/useVideoCall';
import { useMediaDevices } from '@/composables/useMediaDevices';
import { coreApi } from '@/api/core';

const {
  showVideoCallModal,
  acceptCall,
  rejectCall,
  uiState,
  leavingCall,
  joiningCall,
  changeState,
  online,
} = useVideoCall();

const {
  selectedCamera, selectedAudioIn, selectedAudioOut, checkMediaDevices, supportsAudioOutputSetting,
} = useMediaDevices();

onMounted(async () => {
  // agent is online but has missing media permission, changeState to offline
  if (!await checkMediaDevices() && online.value) {
    console.log('[videocall] agent is online but has missing media permission, changeState to offline');
    await changeState(false);
  }
});

const localTrackEl = ref();
const remoteTrackEl = ref();

let room: Room | undefined;

const cleanupTrack = (track: LocalTrack | RemoteTrack | null) => {
  if (!track) {
    return;
  }

  if (track.kind === 'video' || track.kind === 'audio') {
    console.log(`[videocall] cleanup ${track.kind} track ${track.name}`);
    const attachedElements = track.detach();
    attachedElements.forEach((element) => element.remove());

    if ('stop' in track && typeof track.stop === 'function') {
      console.log(`[videocall] stopped ${track.kind} track ${track.name}`);
      track.stop();
    }
  }
};

const setupLocalTracks = async () => {
  const videoConstraints: CreateLocalTrackOptions = {};

  const audioConstraints: CreateLocalAudioTrackOptions = {};

  if (selectedCamera.value?.deviceId) {
    videoConstraints.deviceId = { exact: selectedCamera.value.deviceId };
  } else {
    videoConstraints.facingMode = 'user';
  }

  if (selectedAudioIn.value?.deviceId) {
    audioConstraints.deviceId = { exact: selectedAudioIn.value.deviceId };
  }

  console.log('[videocall] setup local tracks', {
    audio: audioConstraints,
    video: videoConstraints,
  });

  const localTracks = await createLocalTracks({
    audio: audioConstraints,
    video: videoConstraints,
  });

  const localVideoTrack = localTracks.find((t) => t.kind === 'video');
  if (localVideoTrack?.kind === 'video') {
    if (localTrackEl.value) {
      localTrackEl.value.appendChild(localVideoTrack.attach());
      console.log('[videocall] attached local video track');
    }
  }

  const localAudioTrack = localTracks.find((t) => t.kind === 'audio');
  if (supportsAudioOutputSetting.value && localAudioTrack?.kind === 'audio' && selectedAudioOut.value?.deviceId) {
    const audioElement = localAudioTrack.attach();
    if ('setSinkId' in audioElement && typeof audioElement.setSinkId === 'function') {
      audioElement.setSinkId(selectedAudioOut.value.deviceId).then(() => {
        document.body.appendChild(audioElement);
      });
    }
  }

  return localTracks;
};

const clearTracks = async () => {
  if (room) {
    console.log('[videocall] clear local tracks');
    room.localParticipant.tracks.forEach((publication) => {
      cleanupTrack(publication.track);
    });

    console.log('[videocall] clear remote tracks');
    room.participants.forEach((participant) => {
      participant.tracks.forEach((publication) => {
        cleanupTrack(publication.track);
      });
    });
  }
};

const leaveCall = async () => {
  console.log('[videocall] leaving call');
  leavingCall.value = true;
  if (room) {
    try {
      try {
        await clearTracks();
      } catch (e) {
        // noop
      }

      await coreApi.videocalls.agent.resetCall.mutate();

      room.disconnect();
      room = undefined;
    } catch (e) {
      leavingCall.value = false;
    }
  }
};

const handleRemoteTrack = async (track: RemoteTrack) => {
  if (
    track
      && (track.kind === 'video' || track.kind === 'audio')
      && remoteTrackEl.value
  ) {
    const trackElement = track.attach();
    if (
      supportsAudioOutputSetting.value
        && selectedAudioOut.value?.deviceId
        && track.kind === 'audio'
        && 'setSinkId' in trackElement
        && typeof trackElement.setSinkId === 'function') {
      trackElement.setSinkId(selectedAudioOut.value.deviceId);
      console.log(`[videocall] set sink of remote participant ${track.kind} track to ${selectedAudioOut.value.label}`);
    }

    remoteTrackEl.value.appendChild(trackElement);
    console.log(`[videocall] attached ${track.kind} track of remote participant`);
  }
};

const handleAcceptCall = async () => {
  try {
    console.log('[videocall] accepting call..');
    const { callId, token } = await acceptCall();
    const localTracks = await setupLocalTracks();

    room = await connect(token, { name: callId, tracks: localTracks, region: 'de1' });

    room.on('participantConnected', (participant) => {
      console.log(`[videocall] remote participant connected ${participant}`);

      // attach already subscribed publications/tracks
      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed && publication.track) {
          handleRemoteTrack(publication.track);
        }
      });

      // listen for new tracks
      participant.on('trackSubscribed', (track) => {
        console.log(`[videocall] received new remote ${track.kind} track`);
        handleRemoteTrack(track);
      });
    });

    room.on('participantDisconnected', (participant) => {
      console.log(`[videocall] a remote participant disconnected: ${participant}`);
      leaveCall();
    });
  } catch (error) {
    joiningCall.value = false;
    console.error('[videocall] unable to connect to Room', error);
  }
};

const handleRejectCall = async () => {
  await rejectCall();
};

/* TODO should we really disconnect on page reload?!
window.addEventListener('beforeunload', () => {
  changeState(false);
  if (room) {
    room.disconnect();
  }
}); */

watchEffect(() => {
  const appEl = document.getElementById('app');
  if (appEl) {
    if (showVideoCallModal.value) {
      appEl.classList.add('call-active');
    } else {
      appEl.classList.remove('call-active');
    }
  }
});
</script>

<template>
  <Dialog
    class="videocall-modal"
    :class="[`videocall-modal--${uiState}`]"
    :visible="showVideoCallModal"
    :closable="false"
    :draggable="false"
    :show-header="false"
    :pt="{
      mask: { class: 'videocall-modal-mask' },
    }"
    modal>
    <div
      v-if="uiState === 'ringing'"
      class="flex flex-col items-center">
      <div class="flex flex-col items-center pb-space-2">
        <FontAwesomeIcon
          class="text-2xl"
          icon="bell"
          shake />
        <div class="mt-space-4 text-xl">
          {{ $t('videoCall.status.ringing') }}
        </div>
      </div>

      <div class="mt-space-4 flex gap-space-4">
        <Button
          severity="danger"
          rounded
          @click="handleRejectCall">
          <template #icon="{ class: iconClass }">
            <FontAwesomeIcon
              icon="phone"
              class="fa-rotate-by"
              style="--fa-rotate-angle: 135deg;"
              :class="iconClass" />
          </template>
        </Button>
        <Button
          severity="success"
          rounded
          @click="handleAcceptCall">
          <template #icon="{ class: iconClass }">
            <FontAwesomeIcon
              icon="phone"
              :class="iconClass" />
          </template>
        </Button>
      </div>
    </div>

    <div
      v-if="uiState === 'joining' || uiState === 'leaving'"
      class="flex flex-col items-center">
      <FontAwesomeIcon
        class="text-2xl"
        icon="spinner"
        spin />

      <div class="mt-space-4 text-l">
        <template v-if="uiState === 'joining'">
          {{ $t('videoCall.status.joining') }}
        </template>
        <template v-if="uiState === 'leaving'">
          {{ $t('videoCall.status.leaving') }}
        </template>
      </div>
    </div>

    <div
      v-show="uiState === 'in-call'">
      <div
        class="video-tracks">
        <div
          ref="localTrackEl"
          class="video-tracks__local" />
        <div
          ref="remoteTrackEl"
          class="video-tracks__remote" />
      </div>

      <div class="actions">
        <Button
          severity="danger"
          rounded
          @click="leaveCall">
          <template #icon="{ class: iconClass }">
            <FontAwesomeIcon
              icon="phone"
              class="fa-rotate-by"
              style="--fa-rotate-angle: 135deg;"
              :class="iconClass" />
          </template>
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<style lang="scss">
.p-dialog-mask.videocall-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

#app.call-active {
  filter: blur(5px);
}

.p-dialog.videocall-modal {
  max-height: none;
  max-width: 95vw;
  transition: all 250ms ease-in-out;

  .p-dialog-content {
    height: 100%;
    border-radius: var(--border-radius-default);
    background: var(--secondary-color);
    color: var(--surface-0);
    padding: var(--space-5);
  }

  &--in-call {
    width: 95vw;
    height: 95vh;

    .p-dialog-content {
      padding: 0;

      > div {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .video-tracks {
          flex: 1 1 auto;
          position: relative;
          overflow: hidden;

          video {
            width: 100%;
            max-height: 100%;
          }

          &__remote {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: var(--surface-900);
          }

          &__local {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
            width: 320px;
            height: 240px;
            background: var(--surface-0);
          }
        }

        .actions {
          padding: var(--space-5);
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &--ringing {
    .p-button {
      width: 3rem;
      height: 3rem;
    }
  }
}
</style>
