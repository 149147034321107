<script setup lang="ts">
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { storeToRefs } from 'pinia';
import { useModalStore } from '@/store/modalStore';
import { watch, ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import { z } from 'zod';
import { useTRPCError } from '@/composables/useTRPCError';
import { toTypedSchema } from '@vee-validate/zod';
import { coreApi } from '@/api/core';

const { isResetPasswordModalOpen } = storeToRefs(useModalStore());

const hasPasswordBeenChanged = ref(false);

const { handleTRPCError } = useTRPCError();

const { handleSubmit, resetForm, errors } = useForm({
  validationSchema: toTypedSchema(z.object({
    email: z.string().nonempty().email().default(''),
  })),
});

const {
  value: email,
  handleBlur: emailBlur,
  meta: emailMeta,
} = useField<string>('email');

const firebaseErrorCode = ref('');

const onSubmit = handleSubmit(async (values) => {
  try {
    await coreApi.user.sendResetPasswordToken.mutate({ email: values.email });

    hasPasswordBeenChanged.value = true;
  } catch (e) {
    handleTRPCError(e);
  }
});

watch(isResetPasswordModalOpen, () => {
  resetForm();
  hasPasswordBeenChanged.value = false;
  firebaseErrorCode.value = '';
  email.value = '';
});
</script>

<template>
  <Dialog
    v-model:visible="isResetPasswordModalOpen"
    :draggable="false"
    modal
    :closable="true"
    :header="$t('global_modals.reset_password')"
    class="w-90vw lg:w-40vw">
    <div v-if="hasPasswordBeenChanged">
      <p class="text-center">
        {{ $t('global_modals.reset_password_email_sent') }}
      </p>
    </div>
    <div
      class="flex flex-col"
      v-else>
      <div class="text-left">
        {{ $t('global_modals.reset_password_text') }}
      </div>

      <form
        class="mt-space-4"
        @submit="onSubmit">
        <div class="flex flex-col gap-space-2">
          <label for="email">{{ $t('forms.placeholder.email') }}</label>
          <InputText
            id="email"
            class="w-full"
            :class="{ 'p-invalid': !!(errors.email && emailMeta.touched) }"
            @blur="emailBlur"
            type="text"
            v-model.trim="email" />
          <span
            class="p-error mb-space-4 text-left"
            v-if="errors.email && emailMeta.touched">
            {{ errors.email }}
          </span>
        </div>

        <div class="mt-space-5 flex justify-end">
          <Button
            :disabled="false"
            type="submit"
            class="ml-auto w-max text-center font-bold"
            :label="$t('generic.confirm')" />
        </div>
      </form>
    </div>
  </Dialog>
</template>
