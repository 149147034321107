import { auth } from '@/firebase';
import { useVideoCall } from '@/composables/useVideoCall';

const { online, changeState } = useVideoCall();

export async function logout() {
  // ensure video agents are set OFFLINE
  if (online.value) {
    await changeState(false);
  }

  await auth.signOut();
}
