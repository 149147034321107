import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

export const useModalStore = defineStore('modalStore', () => {
  const isResetPasswordModalOpen = ref(false);
  const userHasChangedPassword = ref(false);
  const hasBeenUnauthorized = ref(false);

  const afterLoginRoutePath = ref<RouteLocationNormalized | null>(null);

  return {
    userHasChangedPassword,
    isResetPasswordModalOpen,
    hasBeenUnauthorized,
    afterLoginRoutePath,
  };
});
