<script setup lang="ts">
import { RouterView } from 'vue-router';
import ModalsController from '@/components/modals/ModalsController.vue';
import { initFirebaseAuth } from '@/helpers/initFirebaseAuth';
import { onMounted } from 'vue';
import { appReady } from '@/utils/app-state';
import VRStarLogo from '@/components/svgs/VRStarLogo.vue';
import { useLocale } from '@/composables/useLocale';

const { initAuth } = initFirebaseAuth();
onMounted(() => {
  initAuth();
});

const { updateLocale } = useLocale();
updateLocale();
</script>

<template>
  <router-view v-slot="{ Component, route }">
    <Transition name="fade">
      <div v-if="appReady">
        <ModalsController />
        <div
          :key="route.path">
          <component :is="Component" />
        </div>
      </div>

      <div
        v-else
        class="app-loader">
        <VRStarLogo :ripples="2" />

        <FontAwesomeIcon
          icon="spinner"
          spin />
      </div>
    </Transition>
  </router-view>
</template>

<style lang="scss">
@import 'modern-normalize' layer(normalize);
@import '@/styles/base.scss';
@import '@/styles/vrstar-theme/theme.scss';

.app-loader {
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-size: var(--font-size-2xl);
  color: #fff;
  background: var(--secondary-color);

  .vrstar-logo {
    width: 220px;
    margin-bottom: var(--space-4);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
