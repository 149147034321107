import { BehaviorSubject } from 'rxjs';
import { authUser$ } from './auth';

export const selectedBic$ = new BehaviorSubject<string | null>(null);

async function persist(bic: string | null) {
  if (authUser$.value) {
    try {
      if (bic) {
        sessionStorage.setItem(`selected-bic__${authUser$.value.uid}`, bic);
      } else {
        sessionStorage.removeItem(`selected-bic__${authUser$.value.uid}`);
      }
    } catch (e) {
      // noop
    }
  }
}

export async function restore() {
  if (authUser$.value) {
    try {
      const bic = sessionStorage.getItem(`selected-bic__${authUser$.value.uid}`);

      if (bic) {
        selectedBic$.next(bic);
      }
    } catch (e) {
      // noop
    }
  }
}

export async function changeBic(bic: string | null) {
  await persist(bic);
  selectedBic$.next(bic);
}
