import type { ObjectValues, ObjectKeys } from '@vrstar/lib/browser';

export const routeNames = {
  Login: 'Login',
  Registration: 'Registration',
  RegistrationUser: 'RegistrationUser',
  RegistrationBank: 'RegistrationBank',
  RegistrationDone: 'RegistrationDone',
  RegistrationContinue: 'RegistrationContinue',
  Home: 'Home',
  UserManagement: 'UserManagement',
  EditBankUser: 'EditBankUser',
  CreateUser: 'CreateUser',
  EventManagement: 'EventManagement',
  CreateEvent: 'CreateEvent',
  EditEvent: 'EditEvent',
  ListEvents: 'ListEvents',
  CreateOrganizer: 'CreateOrganizer',
  EditOrganizer: 'EditOrganizer',
  ListOrganizers: 'ListOrganizers',
  CreateVenue: 'CreateVenue',
  EditVenue: 'EditVenue',
  ListVenues: 'ListVenues',
  ListEventImports: 'ListEventImports',
  EventImportDetails: 'EventImportDetails',
  UserProfile: 'UserProfile',
  PartnerOfferManagement: 'PartnerOfferManagement',
  ListPartnerOffers: 'ListPartnerOffers',
  CreatePartnerOffer: 'CreatePartnerOffer',
  EditPartnerOffer: 'EditPartnerOffer',
  ReviewPage: 'ReviewPage',
  ReviewUsersList: 'ReviewUsersList',
  ReviewUserDetail: 'ReviewUserDetail',
  ReviewEventsList: 'ReviewEventsList',
  ReviewEventDetail: 'ReviewEventDetail',
  ReviewOffersList: 'ReviewOffersList',
  ReviewOfferDetail: 'ReviewOfferDetail',
  ConfirmEndUserEmail: 'ConfirmEndUserEmail',
  ConfirmUserEmail: 'ConfirmUserEmail',
  ResetPassword: 'ResetPassword',
  BankManagement: 'BankManagement',
  ListBanks: 'ListBanks',
  EditBank: 'EditBank',
  BankProfile: 'BankProfile',
  ContentFeed: 'ContentFeed',
  ContentFeedList: 'ContentFeedList',
  ContentFeedCreate: 'ContentFeedCreate',
  ContentFeedEdit: 'ContentFeedEdit',
  VideoCallPage: 'VideoCallPage',
  RealEstatePage: 'RealEstatePage',
} as const;

export type RouteNamesTypes = ObjectValues<typeof routeNames>
export type RouteName = ObjectKeys<typeof routeNames>
